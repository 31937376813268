import { IEntryPackEntryType } from '@/vue_apps/catalogs_root/EntryPacks/interfaces/IEntryPackEntryType'
import { ItemBase } from '@/_api/_classes/ItemBase'
import { checkCanAddWithTeeth } from '@/vue_present/Reuse/EntryTypes/shared/checkCanAddWithTeeth'
import { IMeasureUnit } from '@/_declarations/IMeasureUnit'

export class EntryPackEntryType extends ItemBase implements IEntryPackEntryType {
  protected _defaultAmount = 1

  _permit = ['amount', 'id', 'kind', 'price', 'title', 'teethMap', 'measureUnit']

  amount: number = 1

  id: number = null

  kind: number = null

  price: string | number = 0

  title: string = ''

  isRemovable = true

  removed = false

  teethMap = false

  measureUnit: IMeasureUnit

  constructor (props?: Partial<IEntryPackEntryType>) {
    super()
    this._fillProps(props)
    this._defaultAmount = this.amount
  }

  get defaultAmount () {
    return this._defaultAmount
  }

  setAmount (amount: number, updateDefaultAmount = true): void {
    this.amount = amount

    if (updateDefaultAmount) {
      this._defaultAmount = amount
    }
  }

  remove () {
    this.removed = true
  }

  restore () {
    this.removed = false
  }

  canAddWithTeeth () {
    return checkCanAddWithTeeth(this.getAttributes())
  }
}
